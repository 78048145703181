<script setup>
import PlanCard from "@/components/plans/card/PlanCard.vue";

import { ref, onMounted } from 'vue';
import { storeToRefs } from "pinia";
import { useModalStore } from "@/stores/modals";

const { showCallModal, showFilterModal } = storeToRefs(useModalStore());
const base = import.meta.env.BASE_URL;
const vstr = import.meta.env.VITE_VSTR;
const modalStore = useModalStore();

const props = defineProps({
  plans: Object,
  state: String,
  address: String,
});

// this is used to reset the tour. remove this block to make tour appear only once
const tour = ref(null);
onMounted(() => {
  tour.value.resetTour();
});

const steps = [
  {
    target: '.step1',
    content: 'Important - please read',
	  title: 'heading',
  },
  {
    target: '.step2',
    content: 'Change estimates to monthly/annual',
  },
  {
    target: '.step3',
    content: 'Offers displayed are ranked lowest to highest compared to the reference price and average usage for the address entered by you',
  },
  {
    target: '.step4',
    content: 'You may enter your usage from your bills for more accuracy. Recommended if you have Solar panels or Demand meter',
  },
  {
    target: '.step5',
    content: 'See detailed plan information here',
  },
  {
    target: '.step6',
    content: 'Customise your choices using filters',
  },
   {
    target: '.el-carousel__arrow.el-carousel__arrow--right',
    content: 'Scroll for more offers',
  },
   {
    target: '.step8',
    content: 'Select your plan and get started!',
  },
];

const onTourEnd = () => {
  console.log('Tour ended!');
  var element = document.getElementById("tbst-cover");
  element.classList.add("hidden");
};
</script>

<template>
  <VTour ref="tour" :steps="steps" autoStart @onTourEnd='onTourEnd' highlight/>
  <div id="tbst-cover" class="fixed bg-black opacity-10 top-0 left-0 z-40 block h-screen w-screen "></div>
  <div class="grid">
    <div class="lg:container p-0 min-h-screen relative">
      <div class="w-full py-4 flex flex-row justify-between gap-2">
        <img class="h-8" :src="base + 'icons/' + 'COMPARABLE-Logo-Transparents-04.png'"/>
        <input class="w-full border border-gray-200 text-gray-400 rounded-full px-4" type="text" :value="modalStore.formatted_address" readonly />
        <div class="filter-block flex-none text-sm text-left rounded-full border border-blue text-blue px-3 py-1">
          <span class="cursor-pointer step6" @click.prevent="showFilterModal = true">
            <font-awesome-icon icon="fa-solid fa-filter" /> Filter
          </span>
        </div>
      </div>
      <div class="font-poppins text-xs p-2 mb-1 step1">
        <sup>*</sup>All amounts or prices presented are inclusive of GST. Solar rebates for
        residential customers are exempt from GST.
      </div>
      <el-carousel trigger="click" :autoplay="false" :loop="true" arrow="always" :interval="100000" height="1200px" indicator-position="none">
        <el-carousel-item class="h-full" v-for="(plan, key) in plans" :key="key">
          <div class="plan-item pb-1" :class="'plan-item-'+key">
            <div class="plan-body text-dark">
              <PlanCard :vstr="vstr" :planKey="key" :planLength="plans.length" :elec="plan.elec" :gas="plan.gas" :state="props.state" :address="props.address"></PlanCard>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<style>
.el-carousel {
  @apply h-max overflow-y-hidden;
}
.el-carousel__item {
  @apply overflow-hidden;
}
.el-carousel .el-carousel__arrow {
  @apply relative top-7 left-auto right-auto bottom-auto;
}
.el-carousel .el-carousel__arrow.el-carousel__arrow--right {
  @apply -right-1;
}
.el-carousel__indicators {
  @apply bottom-10 fixed h-8 overflow-hidden;
}
.el-carousel__indicator .el-carousel__button {
  @apply bg-gray-1 h-2 w-1;
}
.el-carousel__indicator.is-active .el-carousel__button {
  @apply bg-black h-2 w-1;
}
.el-carousel__arrow--left {
  @apply top-1/2 -mt-16 inset-x-0 lg:inset-x-39perc bg-blue fixed;
}
.el-carousel__arrow--right {
  @apply top-1/2 -mt-16 -mr-4 lg:mr-0 lg:inset-x-3/4 bg-blue fixed;
}
.el-switch__core .el-switch__action {
  @apply bg-lightBlue;
}
#vjt-tooltip {@apply text-lg p-4}
.vjt-actions button{@apply bg-blue}
.vjt-highlight {
  position: relative;
  /* z-index: 50; */
  outline: 1px solid rgb(149 216 252);
  outline-offset: 2px;
  border-radius: 4px;
  background: rgb(149 216 252);
}
@media (max-width: 768px) {
  .el-carousel__container {
    height: 1400px !important;
  }
}
</style>
